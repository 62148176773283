<template>
  <div class="post">
    <router-link class="post__image btn--link" :to="{ name: 'BlogSingle', params: { post: post.link } }" >
        <img :src="post.image" alt="">
    </router-link>
    <div class="post__content">
      <router-link class="post__title" :to="{ name: 'BlogSingle', params: { post: post.link } }" v-text="post.title" />
      <div class="post__text" v-html="post.excerpt" />
      <div class="post__meta">
         <span v-text="post.date" />
          <i>&#8226;</i>
        <span>By {{post.author}}</span>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  name: 'PostCard',
  props: {
    post: Object,
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.post {

  &__image {
    @include image-box;
    margin-bottom: 20px;
    min-height: 265px;
  }

  &__title {
    display: inline-block;
    vertical-align: middle;
    margin-bottom: 20px;
    font-size: 24px;
    color: $text;
    font-weight: 400;
    text-decoration: none;

    @include breakpoint(0, $lg) {
      font-size: 22px;
    }

    @include breakpoint(0, $sm) {
      font-size: 20px;
    }
  }

  &__text {
    font-size: 14px;
    color: $text;
    padding-bottom: 15px;
    :deep {
      p {
        font-size: inherit;
        line-height: inherit;
        color: inherit;
      }
    }
  }

  &__meta {
    font-size: 13px;
    color: $text;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    i {
      padding: 0 10px;
      font-style: normal;
      color: $grey-text;
    }
  }

}
</style>

<template>
  <div class="status-card">
    <ul class="status-card__list list-unstyled">
      <li
        v-for="status in statuses"
        :key="status.color"
        class="status-card__item"
      >
        <i
          class="status-card__icon"
          :style="{ backgroundColor: status.color }"
        />
        <span :style="{ color: status.color }">
           {{status.caption}}
        </span>
      </li>
    </ul>
  </div>
</template>

<script>

export default {
  name: 'StatusList',
  props: {
    statuses: Object,
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.status-card {
  padding: 30px;
  border-radius: $border-radius * 3;
  background-color: $light;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);

  &__list {

  }

  &__item {
    margin-bottom: 15px;
    font-size: 20px;
    display: flex;
    align-items: center;
    white-space: nowrap;

    //@include breakpoint(0, $lg) {
    //  font-size: 15px;
    //}

    @include breakpoint(0, $sm) {
      font-size: 11px;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__icon {
    width: 27px;
    height: 27px;
    margin-right: 10px;
    flex: none;
    border-radius: 6px;

    @include breakpoint(0, $sm) {
      width: 16px;
      height: 16px;
      margin-right: 5px;
    }
  }

}
</style>

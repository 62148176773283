<template>
  <div class="">
    <post-header
      :title="header.title"
      :subtitle="header.subtitle"
      :image="header.image"
    />

    <div class="post">
      <div class="post__container container">
        <div class="post__row">
          <div class="post__content">
            <div class="post__meta">
              <span v-text="meta.date" />
              <i>&#8226;</i>
              <span>By {{meta.author}}</span>
              <div>Updated {{meta.dateUpdated}}</div>
            </div>
            <div class="post__text" v-html="content"/>
          </div>
          <post-sidebar class="post__sidebar" :posts="resentPosts" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import PostHeader from '@/components/blog-single-page/PostHeader.vue';
import PostSidebar from '@/components/blog-single-page/PostSidebar.vue';
import postImage1 from '@/assets/images/post-image-1.jpg';
import postImage2 from '@/assets/images/post-image-2.jpg';

export default {
  name: 'BlogSingle',
  components: {
    PostHeader,
    PostSidebar,
  },
  data() {
    return {
      header: {
        image: postImage2,
        title: 'Nasdaq to Spin Out Market for Pre-IPO Shares in Deal',
        subtitle: 'After months of gridlock and years of litigation, thousands of communities suing the nation’s three largest opioid distributors will announce this week they plan to move forward. ',
      },
      meta: {
        date: 'May 15th 2021',
        dateUpdated: 'July 12th 2021',
        author: 'Terry Colby',
      },
      content: `
        <p>After months of gridlock and years of litigation, thousands of communities suing the nation’s three largest opioid distributors will announce this week they plan to move forward with a $21 billion global settlement that would resolve ongoing and future lawsuits filed by states, cities, counties, Native American tribes and other jurisdictions, according to three people familiar with the negotiations.</p>
        <p>Beyond Identity is the most secure authentication platform in the market. An innovator in passwordless, Beyond Identity’s Passwordless Identity Platform uses real-time access controls to verify identities with devices, and checks granular risk signals for continuous authentication. Breaking down the barriers between cybersecurity, identity, and device management, Beyond Identity fundamentally changes the way the world logs in, enabling teams to continue to work in this new zero trust world. Enterprises and customers turn to Beyond Identity to protect their data and user privacy, and to defend against security and compliance risks. </p>
        <p>We are currently looking for a digital designer to spearhead the company's design. This person will center their design decisions around brand and business goals and will be a team player, working closely with the marketing team and web developer to create great work. This role will be based in our New York office (when we are back in the office).</p>
        <p>We are currently looking for a digital designer to spearhead the company's design. This person will center their design decisions around brand and business goals and will be a team player, working closely with the marketing team and web developer to create great work. This role will be based in our New York office (when we are back in the office).</p>
        <p>We are currently looking for a digital designer to spearhead the company's design. This person will center their design decisions around brand and business goals and will be a team player, working closely with the marketing team and web developer to create great work. This role will be based in our New York office (when we are back in the office).</p>
        <p>We are currently looking for a digital designer to spearhead the company's design. This person will center their design decisions around brand and business goals and will be a team player, working closely with the marketing team and web developer to create great work. This role will be based in our New York office (when we are back in the office).</p>
        <p>We are currently looking for a digital designer to spearhead the company's design. This person will center their design decisions around brand and business goals and will be a team player, working closely with the marketing team and web developer to create great work. This role will be based in our New York office (when we are back in the office).</p>
        <p>We are currently looking for a digital designer to spearhead the company's design. This person will center their design decisions around brand and business goals and will be a team player, working closely with the marketing team and web developer to create great work. This role will be based in our New York office (when we are back in the office).</p>
        <h3>Responsibilities:</h3>
        <ul>
        <li>Produce compelling creative across digital properties that increases awareness in the market and drives brand growth.</li>
        <li>Own website design, landing pages, email graphics, social graphics and digital related campaign design.</li>
        <li>Work with our web developer to deliver easy to navigate experiences for our corporate and localized websites.</li>
        <li>Collaborate with internal product designers and external creative functions such as illustrators, photographers, and videographers to execute industry leading content.</li>
        <li>Ensure our designs adhere to our brand guidelines and maintain consistency throughout all touchpoints in the marketing funnel.</li>
        <li>Collaborate with product and digital marketing managers to tell a compelling, dynamic story through copy and design.</li>
        <li>Prepare graphically engaging presentations for events and executive presentations.</li>
        <li>Support the Marketing team on strategic design briefs for digital, editorial, social media, motion, branded or commercial, by ensuring consistency in look and feel, accuracy, functionality and brand identity.</li>
        </ul>
        <h3>Requirements:</h3>
        <ul>
        <li>5-8+ years of relevant design experience with a portfolio that demonstrates this.</li>
        <li>Previous experience working in a technical environment, within B2B or at a start-up, is preferred.</li>
        <li>Understanding of technical, production and creative constraints, and ability to communicate effective solutions.</li>
        <li>Proficiency in scaling brand identity across systems and an evolving media landscape.</li>
        <li>Excellent communicator and presenter of design proposals and works in progress with excellent ability to absorb and field design critique.</li>
        <li>Process-driven designer who can effectively manage expectations and communication with stakeholders.</li>
        <li>Proficiency in digital design skills and knowledge of Illustrator, Photoshop, Indesign, typography, compost, and photography.</li>
        <li>Ability to manage multiple jobs and meet deadlines.</li>
        <li>Additional skills and knowledge of Figma, After Effects, video editing, and/or illustration are a bonus.</li>
        </ul>
      `,
      resentPosts: [
        {
          id: 1,
          image: postImage1,
          title: 'Nasdaq to Spin Out Market for Pre-IPO Shares in Deal',
          excerpt: `
            <p>Nasdaq Inc. is teaming up with a group of banks including Goldman Sachs Group Inc. and Morgan Stanley to spin out its marketplace for shares of private companies.</p>
            <p>The deal could help drive more transactions to Nasdaq Private Market, the New York-based exchange operator’s trading platform for shares of companies that haven’t yet had an initial public offering...</p>
           `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 2,
          image: postImage2,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Travelers – The insurance company earned $3.45 per share for its second quarter, easily beating the consensus estimate of $2.39. Revenue also topped forecasts, with Travelers benefiting from higher premiums, improved investment returns and lower catastrophe losses. </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
        {
          id: 3,
          image: postImage2,
          title: 'Stocks making the biggest moves premarket: Travelers, Halliburton, IBM, PPG & more',
          excerpt: `
              <p>Travelers – The insurance company earned $3.45 per share for its second quarter, easily beating the consensus estimate of $2.39. Revenue also topped forecasts, with Travelers benefiting from higher premiums, improved investment returns and lower catastrophe losses. </p>
            `,
          date: 'May 15th 2021',
          author: 'Terry Colby',
          link: 'post-1',
        },
      ],
    };
  },
};
</script>

<style lang="scss" scoped>
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.post {
  margin-bottom: 120px;

  @include breakpoint(0, $lg) {
    margin-bottom: 70px;
  }

  &__row {
    display: flex;
    padding: 70px 0;

    @include breakpoint(0, $lg) {
      padding-top: 25px;
    }
  }

  &__content {
    font-size: 16px;
    line-height: 1.7;
    color: $dark;

    @include breakpoint(0, $md) {
      font-size: 14px;
    }
  }

  :deep {

    h3 {
      font-size: 20px;
      margin: 30px 0;
    }

    p {
      font-size: inherit;
      line-height: inherit;
      color: inherit;
    }

  }

  &__meta {
    margin-bottom: 50px;
    font-size: 13px;
    color: $text;
    display: inline-flex;
    flex-wrap: wrap;
    align-items: center;

    @include breakpoint(0, $lg) {
      margin-bottom: 30px;
    }

    @include breakpoint(0, $md) {
      margin-bottom: 20px;
    }

    div {
      width: 100%;
    }

    i {
      padding: 0 10px;
      font-style: normal;
      color: $grey-text;
    }
  }

  &__sidebar {
    width: 30%;
    margin-left: 150px;
    flex: none;
    @include breakpoint(0, $xl) {
      margin-left: 50px;
    }
    @include breakpoint(0, $lg) {
      display: none;
    }
  }

}
</style>

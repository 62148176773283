<template>
  <div
    class="licenses"
    id="licenses"
  >
    <div class="licenses__container container">
      <div class="licenses__row">
        <status-list class="licenses__badge" :statuses="statuses" />
        <states-map
          @activateState="activateState"
          class="licenses__map"
          :states="states"
        />
        <state-badge class="licenses__badge" :state="activeState" />
      </div>
    </div>
  </div>
</template>

<script>
import StatusList from '@/components/licenses-page/StatusList.vue';
import StateBadge from '@/components/licenses-page/StateBadge.vue';
import StatesMap from '@/components/licenses-page/StatesMap.vue';

export default {
  name: 'LicensesSection',
  components: { StatesMap, StatusList, StateBadge },
  data() {
    return {
      activeState: {},
      statuses: [
        {
          caption: 'Active',
          color: '#AF18EE',
        },
        {
          caption: 'Coming Soon',
          color: '#377AFF',
        },
        {
          caption: 'Pending',
          color: '#3DB897',
        },
      ],
      states: [
        {
          name: 'Alabama',
          stateCode: 'AL',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Alaska',
          stateCode: 'AK',
          status: 'Active',
          code: '623604',
        },
        {
          name: 'Arizona',
          stateCode: 'AZ',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Arkansas',
          stateCode: 'AR',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'California',
          stateCode: 'CA',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Colorado',
          stateCode: 'CO',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Connecticut',
          stateCode: 'CT',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Delaware',
          stateCode: 'DE',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Florida',
          stateCode: 'FL',
          status: 'Pending',
          code: '423604',
        },
        {
          name: 'Georgia',
          stateCode: 'GA',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Hawaii',
          stateCode: 'HI',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Idaho',
          stateCode: 'ID',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Illinois',
          stateCode: 'IL',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Indiana',
          stateCode: 'IN',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Iowa',
          stateCode: 'IA',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Kansas',
          stateCode: 'KS',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Kentucky',
          stateCode: 'KY',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Louisiana',
          stateCode: 'LA',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Maine',
          stateCode: 'ME',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Maryland',
          stateCode: 'MD',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Marshall Islands',
          stateCode: 'MA',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Michigan',
          stateCode: 'MI',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Minnesota',
          stateCode: 'MN',
          status: 'Coming Soon',
          code: '423604',
        },
        {
          name: 'Mississippi',
          stateCode: 'MS',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Missouri',
          stateCode: 'MO',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Montana',
          stateCode: 'MT',
          status: 'Coming Soon',
          code: '423604',
        },
        {
          name: 'Nebraska',
          stateCode: 'NE',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Nevada',
          stateCode: 'NV',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'New Hampshire',
          stateCode: 'NH',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'New Jersey',
          stateCode: 'NJ',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'New Mexico',
          stateCode: 'NM',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'New York',
          stateCode: 'NY',
          status: 'Pending',
          code: '423604',
        },
        {
          name: 'North Carolina',
          stateCode: 'NC',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'North Dakota',
          stateCode: 'ND',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Ohio',
          stateCode: 'OH',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Oklahoma',
          stateCode: 'OK',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Oregon',
          stateCode: 'OR',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Pennsylvania',
          stateCode: 'PA',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Rhode Island',
          stateCode: 'RI',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'South Carolina',
          stateCode: 'SC',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'South Dakota',
          stateCode: 'SD',
          status: 'Coming Soon',
          code: '423604',
        },
        {
          name: 'Tennessee',
          stateCode: 'TN',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Texas',
          stateCode: 'TX',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Utah',
          stateCode: 'UT',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Vermont',
          stateCode: 'VT',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Virginia',
          stateCode: 'VA',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Washington',
          stateCode: 'WA',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'West Virginia',
          stateCode: 'WV',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Wisconsin',
          stateCode: 'WI',
          status: 'Active',
          code: '423604',
        },
        {
          name: 'Wyoming',
          stateCode: 'WY',
          status: 'Active',
          code: '423604',
        },
      ],
    };
  },
  methods: {
    activateState(stateCode) {
      this.activeState = this.states.find((state) => state.stateCode === stateCode);
    },
  },
  mounted() {
    [this.activeState] = [this.states[0]];
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";

.licenses {
  padding: 110px 0 70px;

  @include breakpoint(0, $lg) {
    padding: 70px 0 35px;
  }

  @include breakpoint(0, $sm) {
    padding: 35px 0 25px;
  }

  &__row {
    display: grid;
    grid-template-columns: auto 1fr auto;
    grid-gap: 60px;
    align-items: start;

    @include breakpoint(0, $xl) {
      grid-template-columns: 1fr 1fr;
      grid-gap: 40px;
      align-items: stretch;
    }

    @include breakpoint(0, $md) {
      grid-gap: 40px 10px;
    }
  }

  &__map {
    @include breakpoint(0, $xl) {
      grid-area: 2 / 1 / 3 / -1;
    }
  }

  &__badge {
    min-width: 310px;

    @include breakpoint(0, $lg) {
      display: flex;
      flex-direction: column;
      justify-content: center;
      min-width: 30px;
    }
    //width: 310px;
    //max-width: 100%;
  }

}
</style>

<template>
  <div v-if="state" class="status-card">
    <h3 class="status-card__title" v-text="state.name" />
    <div class="status-card__item">
      Status : <span v-text="state.status" />
    </div>
    <div class="status-card__item">
      Code : <span v-text="state.code" />
    </div>
  </div>
</template>

<script>

export default {
  name: 'StateBadge',
  props: {
    state: Object,
  },
  data() {
    return {
    };
  },
  computed: {
    statusColor() {
      switch (this.status) {
        case 'Active':
          return '#AF18EE';
        case 'Pending':
          return '#3DB897';
        default:
          return '#377AFF';
      }
    },
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.status-card {
  border-radius: $border-radius * 3;
  background-color: $light;
  box-shadow: 0 0 13px rgba(0, 0, 0, 0.15);

  &__title {
    padding: 15px 30px;
    margin-bottom: 0;
    color: $text;
    font-weight: 400;
    font-size: 30px;

    @include breakpoint(0, $lg) {
      font-size: 22px;
    }

    @include breakpoint(0, $sm) {
      font-size: 17px;
    }
  }

  &__item {
    padding: 15px 30px;
    font-size: 16px;
    display: flex;
    align-items: center;
    white-space: nowrap;
    border-top: 1px solid rgba(#707070, 0.1);

    @include breakpoint(0, $lg) {
      font-size: 14px;
    }

    @include breakpoint(0, $sm) {
      font-size: 9px;
    }

    span {
      color: $green-500;
      padding-left: 5px;
    }
  }

}
</style>

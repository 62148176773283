<template>
  <header
    class="post-header"
    :style="{backgroundImage: `url(${image})`}"
  >
    <div class="post-header__container container">
     <div class="post-header__content">
       <h1 class="post-header__title" v-html="title" />
       <p v-if="subtitle" class="post-header__subtitle" v-html="subtitle" />
     </div>
   </div>
  </header>
</template>

<script>

export default {
  name: 'PostHeader',
  props: {
    image: String,
    title: String,
    subtitle: String,
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";
.post-header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: 200px 0 110px;
  background-color: $primary;
  background-position: 50% 50%;
  background-repeat: no-repeat;
  background-size: cover;

  @include breakpoint(0, $lg) {
    padding: 150px 0 30px;
  }

  &__content {
    max-width: 960px;
    padding: 40px 150px 90px 60px;
    background-color: rgba(0, 0, 0, 0.26);

    @include breakpoint(0, $lg) {
      padding: 50px;
    }

    @include breakpoint(0, $md) {
      padding: 30px;
    }
  }

  &__title {
    margin-bottom: 20px;
    font-size: 58px;
    color: $light;
    font-weight: 400;

    @include breakpoint(0, $lg) {
      font-size: 40px;
    }

    @include breakpoint(0, $md) {
      font-size: 36px;
    }

    br {
      @include breakpoint(0, $lg) {
        display: none;
      }
    }
  }
  &__subtitle {
    font-size: 16px;
    color: $light;
  }
}
</style>

<template>
  <div class="">
    <promo-section
      class="promo--licenses"
      :subtitle="promo.subtitle"
      :title="promo.title"
      :image="promo.image"
    />
    <licenses-section />
  </div>
</template>

<script>
import PromoSection from '@/components/PromoSection.vue';
import promoImage from '@/assets/images/licenses-promo.svg';
import LicensesSection from '@/components/licenses-page/LicensesSection.vue';

export default {
  name: 'Licenses',
  components: {
    LicensesSection,
    PromoSection,
  },
  data() {
    return {
      promo: {
        subtitle: 'Licenses',
        title: 'No matter your 20, </br> <span class="text-accent">we’ve got you covered.</span>',
        image: promoImage,
      },
    };
  },
};
</script>

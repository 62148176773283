<template>
  <aside class="sidebar">
      <h4 class="sidebar__title" v-text="title" />
      <div class="sidebar__posts">
      <div
        v-for="post in posts"
        :key="post.id"
        class="sidebar__post"
      >
        <post-card class="sidebar__card" :post="post"/>
      </div>
      </div>
  </aside>
</template>

<script>
import PostCard from '@/components/blog-page/PostCard.vue';

export default {
  name: 'PostsSection',
  components: { PostCard },
  props: {
    posts: Array,
  },
  data() {
    return {
      title: 'More articles',
    };
  },
};
</script>

<style scoped lang="scss">
@import "@/scss/utils/_variables.scss";
@import "@/scss/utils/_mixins.scss";

.sidebar {

  &__title {
    margin-bottom: 0;
    padding-bottom: 20px;
    color: $text;
    font-size: 24px;
    font-weight: 400;
    border-bottom: 1px solid $dark;

    @include breakpoint(0, $lg) {
      font-size: 20px;
    }

    @include breakpoint(0, $sm) {
      font-size: 28px;
    }
  }

  &__post {
    padding: 30px 0;
    border-bottom: 1px solid rgba(0, 0, 0, 0.25);

    &:last-child {
      padding-bottom: 0;
      border-bottom: 0;
    }
  }

}
</style>
